<template>
    <div style="background-color: #f2f2f2; margin-top: 8px">
        <el-card style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small" label-width="85px">
                <el-row style="margin-top: 16px">
                    <!--机构类型和机构列表组件-->
                    <ef-dept-type-and-dept-select
                        @selectDept="setDept"
                        @selectDeptType="setDeptType"
                        :need-default-dept-type="true"
                        :need-select-all-dept="true"
                        :componentLoadingCompleteFlag.sync="deptSelectComponentLoadingCompleteFlag"
                    />
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleManualQuery"
                size="small"
                v-if="hasPrivilege('menu.report.wma.cost.change.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.wma.cost.change.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card style="margin-top: 8px">
            <el-table
                id="table"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="460"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column
                    prop="deptName"
                    label="机构名称"
                    width="180"
                    fixed="left"
                    v-if="showColumn('deptName')"
                />
                <el-table-column
                    prop="grossProfit"
                    label="毛利"
                    width="150"
                    fixed="left"
                    v-if="showColumn('grossProfit')"
                />
                <el-table-column prop="startCount" label="期初库存数量" width="120" v-if="showColumn('startCount')" />
                <el-table-column prop="startCost" label="期初库存成本" width="150" v-if="showColumn('startCost')" />
                <el-table-column prop="endCount" label="期末库存数量" width="120" v-if="showColumn('endCount')" />
                <el-table-column prop="endCost" label="期末库存成本" width="150" v-if="showColumn('endCost')" />
                <template v-for="item in wmaBizType2Desc">
                    <el-table-column
                        :label="item.desc + '数量'"
                        :prop="item.type + '数量'"
                        :key="item.type + '数量'"
                        width="120"
                        v-if="showColumn(item.type + '数量')"
                    >
                        <template slot-scope="scope">
                            <span>{{ filterCostSum(scope.row.bizTypeCostSums, item.type).count }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="item.desc + '成本'"
                        :prop="item.type + '成本'"
                        :key="item.type + '成本'"
                        width="150"
                        v-if="showColumn(item.type + '成本')"
                    >
                        <template slot-scope="scope">
                            <span>{{ filterCostSum(scope.row.bizTypeCostSums, item.type).cost }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="item.desc + '金额'"
                        :prop="item.type + '金额'"
                        :key="item.type + '金额'"
                        width="150"
                        v-if="showColumn(item.type + '金额')"
                    >
                        <template slot-scope="scope">
                            <span>{{ filterCostSum(scope.row.bizTypeCostSums, item.type).occurMoney }}</span>
                        </template>
                    </el-table-column>
                </template>
            </el-table>
            <ef-pagination
                :total="total"
                @pageChange="pageChange"
                :componentLoadingCompleteFlag.sync="pageComponentLoadingCompleteFlag"
                :current-page="form.page"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import EfPagination from 'components/EfPagination';
import Util from '@/js/Util';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'WmaDeptCostSummary',
    components: { EfPagination, EfDeptTypeAndDeptSelect, EfStartDate, EfEndDate, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                deptType: null,
                deptCode: null,
                startTime: this.formatDate(new Date()),
                endTime: this.formatDate(new Date()),
                page: 1,
                limit: 100,
            },
            total: 0,
            tableData: [],
            wmaBizType2Desc: Util.allWmaBizType2Desc(),
            pageComponentLoadingCompleteFlag: false,
            deptSelectComponentLoadingCompleteFlag: false,
        };
    },
    mounted() {
        Util.setIntervalAndTimeout(
            () => {
                return this.form.deptCode !== null;
            },
            () => {
                return true;
            },
            () => {
                this.handleQuery();
            },
            3000
        );
    },
    methods: {
        handleExport() {
            UrlUtils.Export(this, '移动加权进销存汇总', '/report/wma/stockCostChange/exportDeptSummaryList', this.form);
        },
        async handleQuery() {
            if (!(this.pageComponentLoadingCompleteFlag && this.deptSelectComponentLoadingCompleteFlag)) {
                //只要有一个false 就代表有组件未加载完成
                return;
            }
            const rst = await this.$efApi.wmaStockCostChangeReportApi.deptSummaryList(this.form);
            this.tableData = rst.data;
            this.total = rst.count;
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        filterCostSum(bizTypeCostSums, bizType) {
            return bizTypeCostSums.find(function (e) {
                return bizType === e.bizType;
            });
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        handlePrint() {
            this.printHTML('table', this.$route.meta.title);
        },
    },
};
</script>
